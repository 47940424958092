import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { endOfYesterday } from 'date-fns';
import { RootState, AppDispatch } from '.'
import api from '../api'
import { Booking } from "../types/booking";
import { fetchInput, isAuthedStateFetchable, sliceState, stateLoaded, stateLoading } from '../types/sliceState';

interface State extends sliceState {
  bookings: Booking[]
}

// Define the initial state using that type
const initialState: State = {
  bookings: [],
  loading: false,
}


export const fetchBookings = createAsyncThunk<
  Booking[],
  fetchInput | void,
  {
    dispatch: AppDispatch
    state: RootState
  }>(
    'bookings/fetch',
    async (_, thunkAPI) => {
      const response = await api.get(`/bookings`, {
        params: {
          from: endOfYesterday().toISOString()
        }
      })
      return response.data.data as Booking[]
    },
    {
      condition: (meta, thunkAPI) => {
        const { bookings: state } = thunkAPI.getState()
        if (!isAuthedStateFetchable(state, meta ?? undefined)) {
          return false
        }
      }
    }
  )

export const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBookings.pending, stateLoading)
    builder.addCase(fetchBookings.fulfilled, (state, action) => {
      stateLoaded(state)
      state.bookings = action.payload
    })
  }
})

export default bookingsSlice.reducer
