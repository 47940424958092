import { useState } from 'react'
import { Link, RouteComponentProps, useHistory} from "react-router-dom"
import axios, { AxiosError } from "axios";


import api from '../../../api'
import { Errors } from '../../../types/error'
import Input from '../../form/Input';

export default function Verify(props: RouteComponentProps<{
    token?: string
    forgot?: string
}>) {
    const history = useHistory()
    const token = props.match.params.token
    
    const forgot = !!((props as any).forgot)
    // const history = useHistory()
    const [email, setEmail] = useState('')
    const [mobile_number, setMobile] = useState('')
    const [child, setChild] = useState('')
    const [password, setPassword] = useState('')
    const [password_confirmation, setPasswordConfirmation] = useState('')
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<Errors>({})
    const [error, setError] = useState<string>('');


    const getError = (field: string) => {
        if (errors[field]) return errors[field][0]
    }


    const submit = async (e: React.SyntheticEvent) => {
        e.preventDefault()
        if (processing) return
        setProcessing(true)
        api
            .post(`/verify/${token}`, {
                email,
                mobile_number,
                child,
                password,
                password_confirmation
            })
            .then(response => {
                return history.push("/")
            })
            .catch((error: Error | AxiosError) => {
                if (axios.isAxiosError(error)) {
                    if (error.response?.status === 422) {
                        setErrors(error.response?.data.errors)
                        return
                    } else if (error.response?.status === 403) {
                        setError(error.response?.data.message)
                        return
                    }
                }
                setError("An Error occurred please try again later")
            })
            .finally(() => {
                setProcessing(false)
            })
    }

    return (
        <>
            <h2 className="mt-12 text-center text-3xl font-extrabold text-gray-900">{forgot ? "Reset your password":"Register your account"}</h2>
            <form className="mt-8 space-y-6" onSubmit={submit}>
                {error?.length > 0 &&
                    <div className="error text-red-500">{error}</div>
                }
                <div className="rounded-md shadow-sm">
                    <div className="text-left my-2">
                        <Input
                            label="Email"
                            error={getError('email')}
                            type="email"
                            name="email"
                            id="email"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                        />
                    </div>
                    <div className="text-left my-2">
                        <Input
                            label="Mobile"
                            error={getError('mobile_number')}
                            type="text"
                            name="mobile_number"
                            id="mobile_number"
                            value={mobile_number}
                            onChange={event => setMobile(event.target.value)}
                        />
                    </div>
                    <div className="text-left my-2">
                        <Input
                            label="Child's First Name"
                            error={getError('child')}
                            type="text"
                            name="child"
                            id="child"
                            value={child}
                            onChange={event => setChild(event.target.value)}
                        />
                    </div>
                    <div className="text-left my-2">
                        <Input
                            label="Password"
                            error={getError('password')}
                            type="password"
                            name="password"
                            id="password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                        />
                    </div>
                    <div className="text-left my-2">
                        <Input
                            label="Confirm Password"
                            error={getError('password_confirmation')}
                            type="password"
                            name="password_confirmation"
                            id="password_confirmation"
                            value={password_confirmation}
                            onChange={event => setPasswordConfirmation(event.target.value)}
                        />
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brand-green hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-dark"
                        disabled={processing}
                    >
                        {processing &&
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block align-middle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        }
                        {forgot ? "Verify and Reset" : "Register"}
                    </button>
                </div>
                <div>
                    <Link
                        className="brand-button-tertiary block rounded-md w-full decoration-none"
                        to={{
                            pathname: `/`,
                            state: { title: 'Go To Login' },
                        }}
                    >Go To Login</Link>
                </div>
            </form>
        </>
    )
}
