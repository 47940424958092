import firebase from "firebase/app"
import "firebase/auth"
import { firebaseConfig } from '../firebase'
import "firebase/analytics";

let firebaseEnv = process.env["REACT_APP_FIREBASE_ENV"] ?? 'production'
const app = firebase.initializeApp(firebaseConfig[firebaseEnv])

export const analytics = app.analytics()
export const auth = app.auth()

export function getToken(): string | null {
  return localStorage.getItem('lf_tkn')
}

export function setToken(token: string) {
  localStorage.setItem('lf_tkn', token)
}

export default app
