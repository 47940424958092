import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkAPI } from ".";
import api from "../api";
import { Term } from "../types/term";
import { fetchInput, isAuthedStateFetchable, sliceState, stateLoaded, stateLoading } from "../types/sliceState";

const ROUTE = '/school-terms';

interface State extends sliceState {
  terms: Term[]
}

const initialState: State = {
  terms: [],
  loading: false
}

export const fetchTerms = createAsyncThunk<Term[], fetchInput | void, ThunkAPI>(
  'terms/fetch',
  async (_, thunkAPI) => {
    const response = await api.get(ROUTE)
    return response.data.data
  },
  {
    condition: (meta, thunkAPI) => {
      const { term:state } = thunkAPI.getState()
      if (!isAuthedStateFetchable(state, meta ?? undefined)) {
        return false
      }
    }
  }
)

export const termSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTerms.pending, stateLoading)
    builder.addCase(fetchTerms.fulfilled, (state, action) => {
      stateLoaded(state)
      state.terms = action.payload
    })
  },
})

export const termSelector = createSelector<RootState, string, Term[], string, Term | undefined>(
  state => state.term.terms,
  (_, id) => id,
  (terms, id) => terms.find(term => term.id === id)
)

// export const {  } = termSlice.actions

export default termSlice.reducer