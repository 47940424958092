import { useEffect, useState } from "react"

import { addBusinessDays, addMonths, addWeeks, differenceInBusinessDays, format, formatISO, isMonday, isPast, isThisWeek, isWeekend, startOfWeek } from "date-fns"
import { differenceInCalendarDays, endOfMonth, fromUnixTime, getUnixTime, isFriday, nextFriday } from "date-fns/esm"
import { DetailedHTMLProps, InputHTMLAttributes } from "react"
import { Term } from "../../types/term"
import Heading from "./Heading"
import DateBlock from "./DateBlock"
import { Student } from "../../types/student"
import { Booking } from "../../types/booking"
import { StudentBasket } from "../../types/studentBasket"

export type TermDateSelectProps = {
  term: Term
  bookings: Booking[]
  basket?: StudentBasket
  currentStudent: Student
  onDateClick?: CallableFunction

} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export default function TermDateSelect({ title, currentStudent, onDateClick, term, bookings, basket, ...props }: TermDateSelectProps) {

  const [currentDisplay, setCurrentDisplay] = useState<number>(0)
  const [selectedStudent, setSelectedStudent] = useState<Student>();

  useEffect(() => {
    if (currentStudent.id !== selectedStudent?.id) {
      setCurrentDisplay(0);
    }
    setSelectedStudent(currentStudent);
  }, [currentStudent, selectedStudent])

  const filteredBookings = bookings.filter((booking) => {
    return booking.studentId === currentStudent.id && getUnixTime(new Date(booking.bookingDate)) >= getUnixTime(new Date())
  }).sort((a, b) => {
    return (getUnixTime(new Date(a.bookingDate)) > getUnixTime(new Date(b.bookingDate))) ? -1 : 1;
  });
  let termBlocks: any[] = [];
  const start = fromUnixTime(term.start);
  const calendarEnd = addMonths(endOfMonth(start), currentDisplay);
  let loadMore = (differenceInBusinessDays(calendarEnd, fromUnixTime(term.end)) < 0);
  if (loadMore && currentDisplay === 0 && differenceInBusinessDays(start, calendarEnd) <= 10) {
    setCurrentDisplay(1);
  }
  const currentBooking = (date: Date) => filteredBookings.find((booking) => {
    return differenceInCalendarDays(new Date(booking.bookingDate), date) === 0
  });
  const dateIsAvailable = (date: Date) => {
    return !!term.excludedDates.find((excludedDate: string) =>
      excludedDate === formatISO(date, { representation: "date" })
    )
  }

  let startDate = fromUnixTime(term.start)
  let endDate = fromUnixTime(term.end);
  //get picker actual start date  
  let startAt = startDate;
  //if the first day is a monday we're pretty happy
  if (!isMonday(startAt)) {
    if (!isWeekend(startAt)) {
      startAt = startOfWeek(startAt, { weekStartsOn: 1 })
    } else {
      startAt = addBusinessDays(startAt, 1);
    }
  }
  if (isPast(startAt)) {
    while (!isThisWeek(startAt, { weekStartsOn: 1 })) {
      startAt = addWeeks(startAt, 1);
    }
    if (isFriday(new Date()) || isWeekend(new Date())) {
      startAt = addWeeks(startAt, 1);
    }
  }
  let endAt = calendarEnd;
  if (endDate && differenceInBusinessDays(endDate, endAt) < 0) {
    endAt = endDate;
  }
  endAt = nextFriday(endAt);
  let currentDate = startAt;
  let dateBlocks: any[] = [];
  while (differenceInBusinessDays(currentDate, endAt) < 0) {
    let weekBlock: any[] = [];
    for (let i = 0; i < 5; i++) {
      let startRelation = differenceInBusinessDays(currentDate, startDate);
      let endRelation = differenceInBusinessDays(currentDate, calendarEnd)
      let termEndRelation = differenceInBusinessDays(currentDate, endDate)
      let todaysBooking = currentBooking(currentDate);

      weekBlock.push(
        <DateBlock
          key={i}
          date={currentDate}
          isAvailable={!dateIsAvailable(currentDate)}
          isBooked={!!todaysBooking}
          isSelected={basket?.bookings.hasOwnProperty(format(currentDate, "yyyy-MM-dd"))}
          isDisabled={startRelation < 0 || endRelation > 0 || termEndRelation > 0 || isPast(currentDate)}
          onDateClick={(date: Date) => {
            if (onDateClick) {
              onDateClick(date, currentStudent, todaysBooking);
            }
          }}
        />
      )

      currentDate = addBusinessDays(currentDate, 1);
    }
    dateBlocks.push(<div key={getUnixTime(currentDate)} className="weekBlock flex flex-row flex-wrap justify-around md:justify-start md:flex-nowrap lg:w-1/2 w-full my-3 md:px-2">{weekBlock}</div>);
  }
  termBlocks.push(
    <div key={getUnixTime(startDate)} >
      <div className="titleSection mb-2">
        <Heading
          text={"Term Start: " + format(startDate, 'MMMM do yyyy')}
          fontSize="sm"
        />
        <hr />
      </div>
      <div className="dateHolder flex flex-row flex-wrap justify-between md:px-10">
        {dateBlocks}
      </div>
    </div>
  )
  return (
    <div className="bookingDateSelector">
      {termBlocks}
      {loadMore &&
        <div className="buttonHolder my-6">
          <button
            className="brand-button text-white"
            onClick={() => {
              setCurrentDisplay(currentDisplay + 1)
            }}
          >Load more dates</button>
        </div>}
    </div>

  )
}
