import { DetailedHTMLProps, InputHTMLAttributes } from "react"

export type HeadingProps = {
  text: string
  classColour?: string
  align?: string
  fontSize?: string
  weight?: string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export default function Heading({ text, classColour, align, fontSize, weight, ...props }: HeadingProps) {
  return (
    <div className="max-w-7xl">
      <h2 className={`text-${fontSize || '3xl'} font-${weight||'normal'} font-cursive text-brand-${classColour || 'green-dark'} text-${align || "left"}`} {...props}>
        {text}
      </h2>
    </div>
  )
}
