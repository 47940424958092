import { getUnixTime } from "date-fns";
import firebase from 'firebase/app'
import '@firebase/auth'

export interface sliceState {
    loading: boolean
    lastFetched?: number
}

export interface fetchInput {
    force?: boolean
}

export const isStateLoading = (state: sliceState) => {
    return state.loading;
}

export const isReasonableTimeframe = (state: sliceState) => {
    if (!state.lastFetched) {
        return true;
    }
    return getUnixTime(new Date()) - state.lastFetched > 1800;
}

export const isStateFetchable = (state: sliceState, meta?: fetchInput) => {
    if (meta?.force && !isStateLoading(state)) {
        return true;
    }
    return !isStateLoading(state) && isReasonableTimeframe(state);
}

export const isAuthedStateFetchable = (state: sliceState, meta?: fetchInput) => {
    if (!firebase.auth().currentUser) {
        return false;
    }
    if (meta?.force && !isStateLoading(state)) {
        return true;
    }
    return isStateFetchable(state)
}


export const stateLoading = (state: sliceState) => {
    state.loading = true;
}
export const stateLoaded = (state: sliceState) => {
    state.loading = false;
    state.lastFetched = getUnixTime(new Date());
}