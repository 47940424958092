import { format, getUnixTime } from "date-fns"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux"
import { Student } from "../../types/student"
import Select from "../form/Select"
import BookingDateSelect from "../utils/BookingDateSelect"
import Heading from "../utils/Heading"
import { Meal } from "../../types/meal"
import MealSelection from "../utils/MealSelection"
import { addBasketBooking, removeBasketBooking, updateBasketBooking } from '../../store/basketSlice'
import { BasketBooking } from "../../types/studentBasket"
import { Menu } from "../../types/menu"

export default function Booking() {
  const terms = useAppSelector(state => state.term.terms.filter((term) => {
    return term.end >= getUnixTime(new Date())
  }).sort((a, b) => {
    return (a.start > b.start) ? -1 : 1
  }))
  const dispatch = useAppDispatch()
  const bookingDates = useAppSelector(state => state.bookings.bookings)
  const bookingsLoading = useAppSelector(state => state.bookings.loading)
  const meals = useAppSelector(state => state.meals.meals)
  const menus = useAppSelector(state => state.menus.menus)
  const basket = useAppSelector(state => state.basket.baskets)
  const students = useAppSelector(state => state.students.students)
  const [currentStudent, setCurrentStudent] = useState<Student | undefined>()

  useEffect(() => {
    if (!currentStudent && students.length > 0) {
      setCurrentStudent(students[0]);
    }
  }, [currentStudent, students, dispatch])
  return (
    <div className="py-8">
      <div className="panel my-4">
        <Heading
          text="Step 1: Select your child" />
        <div className="max-w-xs align-items-left text-left mt-8">
          <Select
            onChange={(selected) => {
              if (selected) {
                setCurrentStudent(students.find(current => selected?.id === current.id))
              }
            }}
            label="Select Child"
            selectedId={currentStudent?.id}
            options={students.map((student) => {
              return {
                id: student.id,
                name: student.forename
              }
            })} />
        </div>
      </div>
      {currentStudent && bookingsLoading &&
        <div className="panel my-4">
          <div className="mt-8 mb-3">
            <div className="flex flex-row align-middle">
              <Heading
                text="Step 2: Loading" />
              <svg className="animate-spin ml-5 mr-3 h-8 w-8 text-brand-green inline-block align-middle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        </div>
      }
      {currentStudent && !bookingsLoading &&
        <><div className="panel my-4">
          <div className="mt-8 mb-3">
            <div className="flex flex-row justify-between align-middle">
              <Heading
                text="Step 2: Select your dates" />
              <Link
                className="text-brand-green inline-block"
                to={{
                  pathname: `/cancel/${currentStudent.id}`,
                  state: { title: 'Cancel Meal Selection' },
                }}
              >Cancel a Booking?</Link>
            </div>
          </div>
          {terms &&
            <BookingDateSelect
              currentStudent={currentStudent}
              terms={terms}
              bookings={bookingDates}
              basket={basket[currentStudent.id]}
              onDateClick={(date: Date, currentStudent: Student, booking?: BasketBooking) => {
                const bookingDate = format(date, 'yyyy-MM-dd')
                const prebooking: BasketBooking = {
                  studentId: currentStudent.id,
                  bookingDate
                }
                if (basket[currentStudent.id]?.bookings[bookingDate]) {
                  dispatch(removeBasketBooking(booking ?? prebooking))
                } else {
                  dispatch(addBasketBooking(booking ?? prebooking));
                }
              }
              }
            />
          }
        </div>
          {basket[currentStudent.id] &&
            <div className="panel my-4">
              <div className="mb-3">
                <Heading
                  text="Step 3: Select your meals" />
              </div>
              <MealSelection
                currentStudent={currentStudent}
                terms={terms}
                basket={basket[currentStudent.id] ?? {}}
                meals={meals}
                menus={menus}
                onMealClick={(date: Date, currentStudent: Student, booking: BasketBooking, meal: Meal, menu: Menu) => {
                  dispatch(updateBasketBooking({ ...booking, mealId: meal.id, menuId: menu.id }))
                }}

              />
            </div>
          }
          {basket[currentStudent.id]?.changes > 0 &&
            <div className="buttonHolder my-6">
              <Link
                className="brand-button text-white"
                to={{
                  pathname: `/confirm/${currentStudent.id}`,
                  state: { title: 'Confirm Meal Selection' },
                }}
              >Confirm Selection</Link>
            </div>
          }
        </>
      }
    </div>
  )
}