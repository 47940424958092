import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState, AppDispatch } from '.'
import api from '../api'
import { Menu } from "../types/menu";
import { fetchInput, isAuthedStateFetchable, sliceState, stateLoaded, stateLoading } from '../types/sliceState';

interface State extends sliceState {
  menus: Menu[]
  loading: boolean
}

// Define the initial state using that type
const initialState: State = {
  menus: [],
  loading: false,
}


export const fetchMenus = createAsyncThunk<
Menu[],
  fetchInput|void,
  {
    dispatch: AppDispatch
    state: RootState
  }>(
    'menus/fetch',
    async (_, thunkAPI) => {
      const response = await api.get(`/menus`)
      return response.data.data as Menu[]
    },
    {
      condition: (meta, thunkAPI) => {
        const { menus: state } = thunkAPI.getState()
        if (!isAuthedStateFetchable(state, meta ?? undefined)) {
          return false
        }
      }
    }
  )

export const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenus.pending, stateLoading)
    builder.addCase(fetchMenus.fulfilled, (state, action) => {
      stateLoaded(state)
      state.menus = action.payload
    })
  }
})

export default menusSlice.reducer
