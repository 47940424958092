import { formatISO, getUnixTime, parseISO, startOfWeek } from "date-fns";
import { format } from "date-fns/esm";
import { DetailedHTMLProps, InputHTMLAttributes } from "react"
import { Meal } from "../../types/meal"
import { Menu } from "../../types/menu";
import { Student } from "../../types/student";
import { StudentBasket } from "../../types/studentBasket";
import { Term } from "../../types/term";

export type MealSelectionProps = {
    terms: Term[]
    currentStudent: Student
    basket: StudentBasket
    meals: Meal[]
    menus: Menu[]
    onMealClick?: CallableFunction
    readonly?: boolean
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

interface ImageList {
    [imgRef: string]: any
}
interface CategorisedMeals {
    [mealtype: string]: any[]
}

const images: ImageList = {
    cold: require("../../assets/cold.png"),
    hot: require("../../assets/hot.png"),
    allergy: require("../../assets/sandwich.png"),
}

export default function MealSelection({ basket, terms, currentStudent, meals, menus, onMealClick, readonly, ...props }: MealSelectionProps) {

    const filteredTerms = terms.filter((term) => {
        return term.schoolId === currentStudent.schoolId && term.end >= getUnixTime(new Date())
    }).sort((a, b) => {
        return (a.start > b.start) ? -1 : 1
    })


    let dateRows: any[] = [];
    if (!basket.bookings) {
        return (<></>)
    }
    
    Object.entries(basket.bookings).sort(([aKey, a], [bKey, b]) => parseISO(aKey) > parseISO(bKey)?1:-1).forEach(([date, selection]) => {
        const bookingDate = parseISO(date)
        const dateU = getUnixTime(bookingDate)
        const menuDate = formatISO(startOfWeek(bookingDate, { weekStartsOn: 1 }), { representation: "date" });
        const bookingDayOfWeek = format(bookingDate, 'iii').toLowerCase();
        //get the relevant term
        let currentTerm = filteredTerms.find((term) => { return dateU >= term.start && dateU <= term.end });
        if (readonly && !selection.mealId) {
            return;
        }
        
        if (currentTerm && currentTerm.menus) {
            let currentMenu: Menu | undefined
            let categorisedMeals: CategorisedMeals = {
                hot: [],
                cold: [],
                allergy: []
            }
            if (currentTerm.menus[menuDate]) {
                currentMenu = menus.find((menu) => menu.id === currentTerm?.menus[menuDate])
                currentMenu?.meals?.filter((menuMeal) => menuMeal.days_available?.includes(bookingDayOfWeek)).forEach((menuMeal) => {
                    let currentMeal = meals.find((meal) => meal.id === menuMeal.id && menuMeal.year_groups?.includes(currentStudent.year))
                    if (!currentMeal || !currentMeal.dinner_type || !categorisedMeals.hasOwnProperty(currentMeal.dinner_type)) {
                        return;
                    }
                    let additionalClass = [];
                    if (!readonly) {
                        if (selection.mealId === currentMeal.id) {
                            additionalClass.push("selected")
                        } else if (selection.oldMealId === currentMeal.id) {
                            additionalClass.push("removed")
                        } else if (selection.mealId) {
                            additionalClass.push("unselected")
                        }
                        categorisedMeals[currentMeal.dinner_type].push(
                            <button key={currentMeal.id}
                                className={`brand-badge ${additionalClass.join(" ")}`}
                                onClick={() => {
                                    if (!readonly && onMealClick) {
                                        onMealClick(date, currentStudent, selection, currentMeal, currentMenu)
                                    }
                                }}
                            >{currentMeal.name}</button>
                        )
                    } else if (selection.mealId === currentMeal.id) {
                        additionalClass.push("selected")
                        categorisedMeals[currentMeal.dinner_type].push(
                            <button key={currentMeal.id}
                                className={`brand-badge ${additionalClass.join(" ")}`}
                                onClick={() => {
                                    if (!readonly && onMealClick) {
                                        onMealClick(date, currentStudent, selection, currentMeal)
                                    }
                                }}
                            >{currentMeal.name}</button>
                        )
                    }
                });
            }
            let categories: any[] = []
            Object.entries(categorisedMeals).forEach(
                ([key, category]) => {
                    if (category.length <= 0) {
                        return
                    }
                    categories.push(
                        <div key={key} className="flex flex-row">
                            <div className="mealTypeImageHolder hidden md:block flex-shrink-0 align-middle px-4 py-2 w-24">
                                <img className="h-auto" src={images[key].default} alt={`${key} Meals`} />
                            </div>
                            <div className="align-middle flex flex-wrap flex-row">
                                {category}
                            </div>
                        </div>
                    )
                }
            );

            dateRows.push(
                <div key={date} className="dateRow flex flex-column flex-wrap md:flex-nowrap md:flex-row shadow-sm rounded-2xl">
                    <div className="lhs w-full md:w-20 flex-shrink-0 text-center bg-brand-green bg-opacity-20 py-4 rounded-t-2xl md:rounded-tr-none md:rounded-l-2xl justify-center flex flex-row md:flex-col">
                        <div key="1" className="day leading-loose font-cursive px-2 md:text-xs align-middle inline-block md:block">{format(bookingDate, 'eeee')}</div>
                        <div key="2" className="date px-2 font-bold leading-normal text-xl md:text-lg align-middle inline-block md:block">{format(bookingDate, 'd')}</div>
                        <div key="3" className="day leading-loose font-cursive px-2 md:text-xs align-middle inline-block md:block">{format(bookingDate, 'MMM')}</div>
                    </div>
                    <div className="rhs flex-grow py-4 border rounded-b-2xl md:rounded-bl-none md:rounded-r-2xl flex flex-col justify-center">
                        {categories.length <= 0 &&
                            <div className=""> There are no meals available to select </div>
                        }
                        {categories}
                    </div>
                </div>
            )
        }

    })

    return (
        <div className="selectionHolder space-y-3">
            {dateRows}
        </div>
    )
}
