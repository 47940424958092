import {
  Switch,
  Route,
} from "react-router-dom";

import logo from '../../assets/lf-logo.png'

import * as LoginScreen from '../screens/auth/Login';
import Verify from '../screens/auth/Verify';

export default function Login() {
  // const history = useHistory()

  return (
    <div className="min-h-screen flex  justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-56 w-auto -mt-12"
            src={logo}
            alt="Love Food"
          />
        </div>
        <Switch>
          <Route path="/register/:token" component={Verify} />
          <Route path="/forgot/:token" component={(props:any)=> <Verify {...props} forgot={true} />} />
          <Route path="/" component={LoginScreen.default} />
        </Switch>
      </div>
    </div>
  )
}
