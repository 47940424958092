import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkAPI } from ".";
import api from "../api";
import { Meal } from "../types/meal";
import { fetchInput, isAuthedStateFetchable, sliceState, stateLoaded, stateLoading } from "../types/sliceState";

export const ROUTE = '/meals';

interface State extends sliceState {
  meals: Meal[]
}

const initialState: State = {
  meals: [],
  loading: false
}

export const fetchMeals = createAsyncThunk<Meal[], fetchInput | void, ThunkAPI>(
  'meals/fetch',
  async (_, thunkAPI) => {
    const response = await api.get(ROUTE)
    return response.data.data
  }, {
    condition: (meta, thunkAPI) => {
      const { meals: state } = thunkAPI.getState()
      if (!isAuthedStateFetchable(state, meta ?? undefined)) {
        return false
      }
    }
  }
)

export const mealSlice = createSlice({
  name: 'meals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMeals.pending, stateLoading)
    builder.addCase(fetchMeals.fulfilled, (state, action) => {
      stateLoaded(state)
      state.meals = action.payload
    })
  },
})

export const mealSelector = createSelector<RootState, string | undefined, Meal[], string | undefined, Meal | undefined>(
  state => state.meals.meals,
  (_, id) => id,
  (meals, id) => id ? meals.find(meal => meal.id === id) : undefined
)

export default mealSlice.reducer