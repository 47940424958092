import { format } from "date-fns/esm";
import { DetailedHTMLProps, InputHTMLAttributes } from "react"
import { BasketBooking } from "../../types/studentBasket";

export type DateBlockProps = {
    date: Date
    isAvailable?: boolean
    isBooked?: boolean
    isSelected?: boolean
    isDisabled?: boolean
    booking?: BasketBooking
    onDateClick?: CallableFunction
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export default function DateBlock({ date, isAvailable, isBooked, isSelected, isDisabled, booking, onDateClick, ...props }: DateBlockProps) {

    const status = (isDisabled) ? 'Disabled' : (isSelected) ? 'Selected' : (isAvailable) ? (isBooked) ?  'Booked' : 'Available' : 'Unavailable';
    let dateHolder = []
    if (!isDisabled) {
        dateHolder.push(<div key="2" className="day text-sm md:text-xs">{format(date, 'eeee')}</div>)
        dateHolder.push(<div key="3" className="date font-bold text-lg">{format(date, 'd')}</div>)
        dateHolder.push(<div key="4" className="day text-sm md:text-xs">{format(date, 'MMM')}</div>)
    }
    function handleClick() {
        if (onDateClick && !isDisabled && isAvailable) {
            onDateClick(date, booking)
        }
    }

    return (
        <div className={`flex dateBlock min-h-24 w-1/3 md:w-1/5 ${status} px-1 my-2 cursor-pointer relative justify-center items-center mb-3`}
            onClick={handleClick}
            onKeyUp={handleClick}
            role="switch"
            tabIndex={Number(format(date, 'yyyyMMdd'))}
            aria-checked={isSelected}>
            <div className="dateInnerHolder h-full w-11/12 text-center mx-auto pb-5 pt-4 bg-black bg-opacity-5 rounded-2xl">
                {dateHolder}
            </div>
            <div className="absolute bottom-0 -mb-3">
                <div className="statusPill mx-auto px-4 py-1 flex rounded-3xl bg-black text-white justify-center text-sm md:text-xs min-w-24 md:min-w-12">{(!isDisabled) ? status : <>&nbsp;</>}</div>
            </div>
        </div>
    )
}
