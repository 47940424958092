import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
interface Props extends RouteComponentProps {
}
//@ts-ignore
function ScrollToTop({ history, children }: Props) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);