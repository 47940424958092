import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState, AppDispatch } from '../store'
import api from '../api'
import firebase from 'firebase/app'
import '@firebase/auth'
import { Parent } from '../types/parent'
import { fetchInput, isAuthedStateFetchable, sliceState, stateLoaded, stateLoading } from '../types/sliceState'

interface State extends sliceState {
  parent?: Parent
  parentLoading?: boolean
}

// Define the initial state using that type
const initialState: State = {
  loading: false,
}


export const fetchParent = createAsyncThunk<
  Parent,
  fetchInput | void,
  {
    dispatch: AppDispatch
    state: RootState
  }>(
    'parent/fetch',
    async (_, thunkAPI) => {
      const uid = firebase.auth().currentUser?.uid;
      const response = await api.get(`/parent/${uid}`)
      return response.data.data as Parent
    },
    {
      condition: (meta, thunkAPI) => {
        const { parent: state } = thunkAPI.getState()
        if (!isAuthedStateFetchable(state, meta ?? undefined)) {
          return false
        }
      }
    }
  )

export const parentSlice = createSlice({
  name: 'parent',
  initialState,
  reducers: {
    commitParent: (state, action: PayloadAction<Parent>) => {
      state.parent = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchParent.pending, stateLoading)
    builder.addCase(fetchParent.fulfilled, (state, action) => {
      stateLoaded(state)
      state.parent = action.payload
    })
  }
})

export const { commitParent } = parentSlice.actions

export default parentSlice.reducer
