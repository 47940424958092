import { LockClosedIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios, { AxiosError } from "axios";

import { auth } from '../../../auth'
import api from '../../../api'


export default function Login() {
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState<string | undefined>()
    const [resetPassword, setResetPassword] = useState(false)


    const signIn = async () => {
        try {
            await auth.signInWithEmailAndPassword(email, password)
            history.replace('/')
        } catch (err: any) {
            setError(err.message)
        }
    }

    const doResetPassword = () => {
        return api
            .post(`/forgot`, {
                email,
            })
            .then(response => {
                return history.push("/")
            })
            .catch((error: Error | AxiosError) => {
                if (axios.isAxiosError(error)) {
                    if (error.response?.status === 422) {
                        setError(error.response?.data.errors[0])
                        return
                    } else if (error.response?.status === 403) {
                        setError(error.response?.data.message)
                        return
                    }
                }
                setError("An Error occurred please try again later")
            })
            .finally(() => {
                setError('If your email is in our system your password reset request has been sent.')
                setResetPassword(false)
            })
    }
    const submit = async (e: React.SyntheticEvent) => {
        e.preventDefault()
        setError(undefined)
        setSubmitting(true)

        if (resetPassword) {
            await doResetPassword()
        } else {
            await signIn()
        }

        setSubmitting(false)
    }

    return (
        <>
            <h2 className="mt-12 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
            <form className="mt-8 space-y-6" onSubmit={submit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label htmlFor="email-address" className="sr-only">
                            Email address
                        </label>
                        <input
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-brand-green-dark focus:border-brand-green-dark focus:z-10 sm:text-sm"
                            placeholder="Email address"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                        />
                    </div>
                    {!resetPassword && (
                        <div>
                            <label htmlFor="password" className="sr-only">
                                Password
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-brand-green-dark focus:border-brand-green-dark focus:z-10 sm:text-sm"
                                placeholder="Password"
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                            />
                        </div>
                    )}
                </div>

                {!resetPassword &&
                    <div className="flex items-center justify-between">
                        <div className="ml-auto text-sm">
                            <button type="button" className="font-medium text-brand-green hover:opacity-75" onClick={() => setResetPassword(true)} disabled={submitting}>
                                Forgot your password?
                            </button>
                        </div>
                    </div>
                }

                <div>
                    <button
                        type="submit"
                        disabled={submitting}
                        className="group relative w-full flex justify-center brand-button"
                    >
                        {submitting &&
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block align-middle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        }
                        {!submitting &&
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                <LockClosedIcon className="h-5 w-5 text-brand-green-dark group-hover:opacity-75" aria-hidden="true" />
                            </span>
                        }
                        {resetPassword ? 'Send Password Reset' : 'Sign in'}
                    </button>
                    {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                </div>
            </form>
        </>
    )
}
