import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useEffect, useState } from "react"
import { Link,  useHistory } from "react-router-dom"
import { useAppDispatch } from "../../hooks/useRedux"
import { RouteComponentProps } from "react-router-dom"
import Heading from "../utils/Heading"
import api from '../../api';
import axios, { AxiosError } from "axios"
import { ORDER_STATUS_CONFIRMED } from '../../store/orderSlice';
import { fetchParent } from '../../store/parentSlice';

export default function SettleBalance(props: RouteComponentProps<{ id?: string }>) {
  const history = useHistory()
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useAppDispatch()

  const [processing, setProcessing] = useState<boolean>(false)
  const [subItems, setSubItems] = useState<{
    [key: string]: {
      quantity?: number
      cost: number
    }
  }>({});
  const [error, setError] = useState<string>('');
  const [orderId, setOrderId] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');

  const proceedToThankYou = () => {
    dispatch(fetchParent({ force: true }))
    history.push("/settle/thanks")
  }

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const startPayment = async (event: any) => {
    if (processing) return
    setProcessing(true)
    api
      .post('balance/settle')
      .then(response => {
        if (response.data.data.status === ORDER_STATUS_CONFIRMED) {
          proceedToThankYou();
        } else {
          setClientSecret(response.data.client_secret);
          let rows = {
            "Total": {
              cost: response.data.total
            }
          };
          setSubItems(rows)
          setOrderId(response.data.data.id);
          setError('');
          setProcessing(false);
        }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 400) {
            return
          }
        }
        setError("We've been unable to prepare your order at this time, please try again later.")
      })
      .finally(() => {
        setProcessing(false)
      })
  }
  const handleSubmit = async (event: any) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements || processing) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setProcessing(true);
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement
        }
      });
      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        api
          .post(`/balance/complete/${orderId}`,)
          .then(response => {
            if (response.data.data.status === ORDER_STATUS_CONFIRMED) {
              setError('');
              setClientSecret('')
              proceedToThankYou();
            }

          })
          .catch((error: Error | AxiosError) => {
            setError("We've been unable to prepare your order at this time, please try again later.")
          })
          .finally(() => {
            setProcessing(false)
          })
      }
    };
  };

  useEffect(() => {
    startPayment({});
  }, [stripe])
  let itemRows: any[] = [];

  Object.entries(subItems).forEach(
    ([key, value]) => {
      itemRows.push(
        <div key={key} className="flex flex-row justify-between p-2">
          <div className="text-black text-left">
            {value.quantity ?? ''} {key.charAt(0).toUpperCase() + key.slice(1)}:
          </div>
          <div className="text-black text-right">
            {new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: 'GBP'
            }).format(value.cost / 100)}
          </div>
        </div>

      )
    }
  );
  return (
    <div className="py-8">
        <div className="panel my-4">
          <div className="mb-3">
            <Heading
              text="Enter your payment details" />
          </div>
          <form onSubmit={handleSubmit}>
            {error?.length > 0 &&
              <div className="error text-red-500">{error}</div>
            }
            <div className="bg-white border appearance-none border-gray-400 my-4 p-6 rounded-2xl">
              <CardElement onChange={handleChange} />
            </div>
            {itemRows}
            <div className="buttonHolder my-6 flex flex-row justify-between">
              <Link
                className="brand-button-tertiary decoration-none"
                to={{
                  pathname: `/`,
                  state: { title: 'Go Back' },
                }}
              >Go Back</Link>
              <button className="brand-button text-white" type="submit" disabled={!stripe || disabled || processing}>
                {processing &&
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block align-middle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                }
                Pay Now
              </button>
            </div>
          </form>
        </div>
    </div>
  );
};