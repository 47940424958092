import {parseISO } from "date-fns";
import { format } from "date-fns/esm";
import { DetailedHTMLProps, InputHTMLAttributes } from "react"
import { Booking } from "../../types/booking";
import { Meal } from "../../types/meal"
import { Student } from "../../types/student";
import { Term } from "../../types/term";

export type CancelMealProps = {
    terms: Term[]
    currentStudent: Student
    bookings: Booking[]
    meals: Meal[]
    onMealClick?: CallableFunction
    readonly?: boolean
    removedBookings: string[]
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>


export default function CancelMeal({ bookings, terms, currentStudent, meals, onMealClick, readonly, removedBookings, ...props }: CancelMealProps) {


    let dateRows: any[] = [];
    if (!bookings) {
        return (<></>)
    }
    bookings.forEach((booking) => {
        const mealArray: any[] = [];

            const bookingDate = parseISO(booking.bookingDate)
            if (!booking.mealId) {
                return;
            }
            const currentMeal = meals.find((meal) => meal.id === booking.mealId)
            if (!currentMeal) {
                return;
            }
            let additionalClass = [];
            additionalClass.push("selected")
            mealArray.push(
                <button key={currentMeal.id}
                    className={`brand-badge ${additionalClass.join(" ")}`}
                >{currentMeal.name}</button>
            )
           let categories: any[] = []
            mealArray.forEach(
                (meal, index) => {
                    categories.push(
                        <div key={index} className="flex flex-row justify-between h-full">
                            <div className="align-middle flex flex-grow h-full">
                                {meal}
                            </div>
                            <div className="flex align-middle p-4">
                                <button
                                    onClick={() => {
                                        if (!readonly && onMealClick) {
                                            onMealClick(booking, currentStudent)
                                        }
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                            </div>
                        </div >
                    )
                }
            );

            dateRows.push(
                <div key={booking.id} className="dateRow flex flex-column flex-wrap md:flex-nowrap md:flex-row shadow-sm rounded-2xl">
                    <div className="lhs w-full md:w-20 text-center bg-brand-green bg-opacity-20 py-4 rounded-t-2xl md:rounded-tr-none md:rounded-l-2xl justify-center flex flex-row md:flex-col">
                        <div key="1" className="day leading-loose font-cursive px-2 md:text-xs align-middle inline-block md:block">{format(bookingDate, 'eeee')}</div>
                        <div key="2" className="date px-2 font-bold leading-normal text-xl md:text-lg align-middle inline-block md:block">{format(bookingDate, 'd')}</div>
                        <div key="3" className="day leading-loose font-cursive px-2 md:text-xs align-middle inline-block md:block">{format(bookingDate, 'MMM')}</div>
                    </div>
                    <div className="rhs flex-grow py-4 border rounded-b-2xl md:rounded-bl-none md:rounded-r-2xl flex flex-col justify-center">
                        {categories.length <= 0 &&
                            <div className=""> There are no meals available to select </div>
                        }
                        {categories}
                    </div>
                </div>
            )
        }

        )

    return (
        <div className="selectionHolder space-y-3">
            {dateRows}
        </div>
    )
}
