import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState, AppDispatch } from '.'
import api from '../api'
import { Student } from "../types/student";
import { fetchInput, isAuthedStateFetchable, sliceState, stateLoaded, stateLoading } from '../types/sliceState';

interface State extends sliceState{
  students: Student[]
}

// Define the initial state using that type
const initialState: State = {
  students: [],
  loading: false,
}


export const fetchStudents = createAsyncThunk<
Student[],
  fetchInput | void,
  {
    dispatch: AppDispatch
    state: RootState
  }>(
    'students/fetch',
    async (_, thunkAPI) => {
      const response = await api.get(`/students`)
      return response.data.data as Student[]
    },
    {
      condition: (meta, thunkAPI) => {
        const { students: state } = thunkAPI.getState()
        if (!isAuthedStateFetchable(state, meta ?? undefined)) {
          return false
        }
      }
    }
  )

export const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStudents.pending, stateLoading)
    builder.addCase(fetchStudents.fulfilled, (state, action) => {
      stateLoaded(state)
      state.students = action.payload
    })
  }
})

export default studentsSlice.reducer
