import { useState, } from 'react'
import api from "../../api";
import axios, { AxiosError } from "axios";
import { useHistory } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux"
import { RouteComponentProps } from "react-router-dom"
import Input from "../form/Input";
import Heading from "../utils/Heading"
import { Errors } from "../../types/error";
import { useEffect } from "react";
import { fetchParent } from "../../store/parentSlice";

export default function Profile(props: RouteComponentProps<{ id?: string }>) {
  const history = useHistory()

  const dispatch = useAppDispatch()

  const parent = useAppSelector(state => state.parent.parent)
  const [password, setPassword] = useState('')
  const [password_confirmation, setPasswordConfirmation] = useState('')
  const [processing, setProcessing] = useState(false)
  const [errors, setErrors] = useState<Errors>({})
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [refundError, setRefundError] = useState<string>('');
  const [refundSuccess, setRefundSuccess] = useState<string>('');

  const getError = (field: string) => {
    if (errors[field]) return errors[field][0]
  }

  useEffect(() => {
    dispatch(fetchParent({ force: true }));
  }, [dispatch]);
  const settleBalance = () => {
    history.push("/settle")
  }
  const requestRefund = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (processing) return
    if (!parent?.balance || parent.balance <= 0) {
      setRefundError('Your balance is too low to request a refund')
      return
    }
    setProcessing(true)
    setRefundError("")
    setRefundSuccess('')
    api
      .post(`/withdraw`)
      .then(response => {
        setRefundSuccess('Your refund request has been submitted')
      })
      .catch((error: Error | AxiosError) => {
        setRefundError("An Error occurred please try again later")
      })
      .finally(() => {
        setProcessing(false)
      })
  };

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (processing) return
    setProcessing(true)
    setError("")
    setSuccess('')
    api
      .post(`/password`, {
        password,
        password_confirmation
      })
      .then(response => {
        setSuccess('Your password has been changed')
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 422) {
            setErrors(error.response?.data.errors)
            return
          } else if (error.response?.status === 403) {
            setError(error.response?.data.message)
            return
          }
        }
        setError("An Error occurred please try again later")
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
    <div className="py-8 max-w-2xl mx-auto">
      <div className="panel my-4">
        <div className="mb-3">
          <Heading
            align="center"
            text="Your Information" />
        </div>
        {(true || parent?.balance) &&
          <>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col items-start">
                <div className="">
                  <Heading
                    fontSize="lg"
                    text="Your Balance" />
                </div>
                {refundError?.length > 0 &&
                  <div className="error text-red-500">{refundError}</div>
                }
                {refundSuccess?.length > 0 &&
                  <div className="success text-brand-green">{refundSuccess}</div>
                }
                {new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP'
                }).format((parent ? (parent.balance || 0) : 0) / 100)}
              </div>
              {(typeof(parent?.balance) != "undefined"  && parent.balance > 0) &&
                <div>
                  <button
                    type="button"
                    onClick={requestRefund}
                    className="brand-button-secondary"
                    disabled={processing}
                  >
                    {processing &&
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block align-middle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    }
                    Request Refund
                  </button>
                </div>
              }
            {(typeof (parent?.balance) != "undefined" && parent.balance < 0) &&
                <div>
                  <button
                    type="button"
                    onClick={settleBalance}
                    className="brand-button-secondary"
                    disabled={processing}
                  >
                    {processing &&
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block align-middle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    }
                    Settle Your Balance
                  </button>
                </div>
              }
            </div>
          </>
        }
        <form className="mt-8 space-y-6" onSubmit={submit}>

          <div className="">
            <Heading
              fontSize="lg"
              text="Change Your Password" />
          </div>

          {error?.length > 0 &&
            <div className="error text-red-500">{error}</div>
          }
          {success?.length > 0 &&
            <div className="success text-brand-green">{success}</div>
          }

          <div className="rounded-md shadow-sm">
            <div className="text-left mb-2">
              <Input
                label="Password"
                error={getError('password')}
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
            </div>
            <div className="text-left my-2">
              <Input
                label="Confirm Password"
                error={getError('password_confirmation')}
                type="password"
                name="password_confirmation"
                id="password_confirmation"
                value={password_confirmation}
                onChange={event => setPasswordConfirmation(event.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative ml-auto flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brand-green hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-dark"
              disabled={processing}
            >
              {processing &&
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block align-middle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              }
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};