import React, { useRef, useState } from 'react';
import './App.css';
import BrandNav from './components/navigation/BrandNav';
import {
  BrowserRouter as Router
} from "react-router-dom";
import Login from './components/navigation/Login';
import { auth } from './auth';
import { setBearerToken } from './api';
import { useAppDispatch } from './hooks/useRedux';
import { setAuthState } from './store/authSlice';
import { fetchStudents } from './store/studentsSlice';
import { fetchTerms } from './store/schoolTermSlice';
import { fetchBookings } from './store/bookingSlice';
import { fetchMeals } from './store/mealSlice';
import { fetchMenus } from './store/menuSlice';
import { fetchParent } from './store/parentSlice';
import ScrollToTop from './hooks/ScrollToTop';
import { useEffect } from 'react';
import Maintenance from './components/navigation/Maintenance';

function App() {
  const maintenance = process.env.REACT_APP_MAINTENANCE ?? false;
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const user = useRef<firebase.default.User | null>()
  // const [interval, keepInterval] = useState<number|undefined>()
  const [interval, keepInterval] = useState<any>()

  useEffect(() => {
    const fetchData = () => {
      dispatch(fetchParent());
      return Promise.all([
        dispatch(fetchStudents()),
        dispatch(fetchTerms()),
        dispatch(fetchBookings()),
        dispatch(fetchMeals()),
        dispatch(fetchMenus()),
      ])
    }

    const handleAuth = async (authChange: any) => {
      const result = await authChange?.getIdTokenResult()
      setBearerToken(result?.token)
      dispatch(setAuthState({
        type: result?.claims.type || '',
        schools: result?.claims.schools || [],
      }))
      const userUpdated = user.current?.uid !== authChange?.uid
      user.current = authChange
      if (userUpdated && user.current) {
        if (result?.claims.type && ['admin', 'staff'].includes(result?.claims.type)) {
          auth.signOut()
          return
        }
        await setLoading(true);
        await fetchData();
        if (!interval) {
          keepInterval(setInterval(fetchData, 30000))
        }
        await setLoading(false)
      } else if (authChange === null && userUpdated) {
        clearInterval(interval)
        window.location.reload()
      } else if (authChange === null) {
        await setLoading(false)
      }
    };
    auth.onIdTokenChanged(handleAuth);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  return (
    <div className="App min-h-screen bg-gray-50">
      {maintenance ? <Maintenance /> :
        loading ? <></> :
          <Router>
            <ScrollToTop>
              
              {!loading && user.current ? <BrandNav /> : <Login />}
            </ScrollToTop>
          </Router>
      }
    </div>
  );
}

export default App;
