import { Link } from "react-router-dom"
import { RouteComponentProps } from "react-router-dom"
import Heading from "../utils/Heading"

export default function ThankYou(props: RouteComponentProps<{ id?: string }>) {

  return (
    <div className="py-8">
        <div className="panel my-4">
          <div className="mb-3">
          <Heading
              align="center"
              text="Thank You" />
          </div>
          <div className="my-8">
            Thank you for your order. Your meal selections have now been confirmed.
          </div>
          <div className="buttonHolder my-6">
          <Link
            className="brand-button text-white"
            to={{
              pathname: `/`,
              state: { title: 'Manage My Bookings' },
            }}
          >Manage my Bookings</Link>
          </div>
        </div>
    </div>
  );
};