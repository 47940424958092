import { getUnixTime } from "date-fns"
import { useState } from "react"
import { Link, Redirect, useHistory } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux"
import { RouteComponentProps } from "react-router-dom"
import Heading from "../utils/Heading"
import { removeBasket } from '../../store/basketSlice'
import api from '../../api';
import axios, { AxiosError } from "axios"
import { fetchBookings } from '../../store/bookingSlice';
import CancelMeal from '../utils/CancelMeal';
import { Student } from '../../types/student';
import { Booking } from '../../types/booking';
import { parseISO } from 'date-fns/esm';

type removedBookings = {
  studentId: string
  bookings: string[]
}

export default function Cancellation(props: RouteComponentProps<{ id?: string }>) {
  const history = useHistory()
  const id = props.match.params.id

  const terms = useAppSelector(state => state.term.terms.filter((term) => {
    return term.end >= getUnixTime(new Date())
  }).sort((a, b) => {
    return (a.start > b.start) ? -1 : 1
  }))
  const dispatch = useAppDispatch()

  const meals = useAppSelector(state => state.meals.meals)
  const bookings = useAppSelector(state => state.bookings.bookings)
  const [removed, setRemoved] = useState<removedBookings>({
    studentId: id ?? '',
    bookings: []
  })
  const currentStudent = useAppSelector(state => state.students.students.find((student => student.id === id)))
  const [processing, setProcessing] = useState<boolean>(false)
  const [error, setError] = useState<string>('');

  if (!id || !currentStudent) {
    return <Redirect push to="/" />
  }
  const filteredBookings = bookings.filter((booking) => currentStudent.id === booking.studentId
    && booking.id && !removed.bookings.includes(booking.id)
    && getUnixTime(parseISO(booking.bookingDate)) > getUnixTime(new Date()))
    .sort((a, b) => parseISO(a.bookingDate) > parseISO(b.bookingDate) ? 1 : -1);
  const proceedToThankYou = () => {
    history.push("/thanks")
    dispatch(removeBasket(id));
  }

  const handleConfirm = async (event: any) => {
    if (processing) return
    setProcessing(true)
    api
      .post('/refund', {
        ...removed,
      })
      .then(response => {
        dispatch(fetchBookings({ force: true }))
        proceedToThankYou();
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 400) {
            return
          }
        }
        setError("We've been unable to cancel your bookings at this time. Please try again later.")
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
    <div className="py-8 max-w-3xl mx-auto">
      <div className="panel my-4">
        <div className="mb-4">
          <Heading
            text={`Cancel an order for: ${currentStudent.forename}`} />
        </div>

        {error?.length > 0 &&
          <div className="error text-red-500">{error}</div>
        }

        <CancelMeal
          currentStudent={currentStudent}
          terms={terms}
          bookings={filteredBookings}
          removedBookings={removed.bookings}
          meals={meals}
          onMealClick={(booking: Booking, student: Student) => {
            let current = { ...removed };
            if (booking.id && !current.bookings.includes(booking.id)) {
              current.bookings.push(booking.id);
              setRemoved(current);
            }
          }}
        />
        <div className="buttonHolder my-6 flex flex-row justify-between">
          <Link
            className="brand-button-tertiary decoration-none"
            to={{
              pathname: `/`,
              state: { title: 'Go Back' },
            }}
          >Go Back</Link>
          <button
            className={`brand-button text-white`}
            type="submit"
            onClick={handleConfirm}
            disabled={processing || removed.bookings.length === 0}
          >
            {processing &&
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block align-middle" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            }
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};