import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import {
  Switch,
  Route,
  NavLink,
  useHistory,
} from "react-router-dom";
import { auth } from '../../auth';
import Booking from '../screens/Booking';
import { useAppSelector } from '../../hooks/useRedux';
import slugify from 'slugify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../screens/CheckoutForm';
import ThankYou from '../screens/ThankYou';
import { format } from 'date-fns/esm';
import logo from '../../assets/lf-logo.png';
import Profile from '../screens/Profile';
import Cancellation from '../screens/Cancellation';
import SettleBalance from '../screens/SettleBalance';
import SettleThankYou from '../screens/SettleThankYou';

// const navigation = ['Schools', 'Meals', 'Menus', 'Reports']
// const topLevelNavigation: string[]= [];
const profile = ['Sign out']

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY||"");
stripePromise.catch(() => { });
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

// { students }: { students: ReactNode }

export default function BrandNav() {
  const type = useAppSelector(state => state.auth.type)
  const history = useHistory()
  const [navigation, setNavigation] = useState<string[]>([])

  useEffect(() => {
    // setNavigation(['Booking'])
    setNavigation(['Booking', 'Profile'])
    history.replace('/', { title: 'Booking' })
  }, [history, type])

  const signOut = async () => {
    await auth.signOut()
  }

  return (
    <Elements stripe={stripePromise}>
      <div className="flex flex-col min-h-screen bg-gray-50">
        <div>
          <Disclosure as="nav" className="print:hidden bg-brand-red border-b border-white border-opacity-25 lg:border-none">
            {({ open }) => (
              <>
                <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                  <div className="relative h-24 flex items-center justify-between lg:border-b lg:border-white lg:border-opacity-25">
                    <div className="px-2 flex items-center lg:px-0">
                      <div className="flex-shrink-0 w-60">
                        <img
                          className="hidden lg:block w-60 absolute top-0"
                          src={logo}
                          alt="Logo"
                        />
                      </div>
                      <div className="hidden lg:block lg:ml-10">
                        <div className="flex space-x-4">
                          {navigation.map((item, itemIdx) =>
                            <NavLink
                              key={item}
                              to={{
                                pathname: itemIdx !== 0
                                  ? `/${slugify(item.toLowerCase())}`
                                  : '/',
                                state: { title: item },
                              }}
                              exact={itemIdx === 0}
                              isActive={(match, location) => {
                                return false
                              }}
                              className="text-white hover:underline rounded-xl py-2 px-3 text-sm font-medium"
                              activeClassName="bg-brand-green text-white"
                            >
                              {item}
                            </NavLink>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex lg:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="bg-brand-red p-2 rounded-md inline-flex items-center justify-center text-white hover:bg-brand-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brand-green focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="hidden lg:block lg:ml-4">
                      <div className="flex items-center">
                        {/* Profile dropdown */}
                        <Menu as="div" className="ml-3 relative flex-shrink-0">
                          {({ open }) => (
                            <>
                              <div>
                                <Menu.Button className="bg-brand-red rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brand-green focus:ring-white">
                                  <span className="sr-only">Open user menu</span>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                                  </svg>
                                  {/* <img
                                  className="rounded-full h-8 w-8"
                                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                  alt=""
                                /> */}
                                </Menu.Button>
                              </div>
                              <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items
                                  static
                                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                  {profile.map((item) => (
                                    <Menu.Item key={item}>
                                      {({ active }) => (
                                        <button
                                          onClick={signOut}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block w-full py-2 px-4 text-sm text-gray-700'
                                          )}
                                        >
                                          {item}
                                        </button>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="lg:hidden">
                  <div className="px-2 pt-2 pb-3 space-y-1 space-x-2">
                    {navigation.map((item, itemIdx) =>
                      <NavLink
                        key={item}
                        to={{
                          pathname: itemIdx !== 0
                            ? `/${item.toLowerCase()}`
                            : '/',
                          state: { title: item },
                        }}
                        exact={itemIdx === 0}
                        className="text-white hover:underline rounded-xl py-2 px-3 text-sm font-medium"
                        activeClassName="bg-brand-green text-white"
                      >
                        {item}
                      </NavLink>
                    )}
                  </div>
                  <div className="pt-4 pb-3 border-t border-white">
                    <div className="mt-3 px-2 space-y-1">
                      {profile.map((item) => (
                        <button
                          key={item}
                          onClick={signOut}
                          className="text-white hover:underline rounded-xl py-2 px-3 text-sm font-medium hover:bg-brand-green hover:bg-opacity-60"
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <main className="flex-grow">
          <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
            <Switch>
              <Route path="/confirm/:id" component={CheckoutForm} />
              <Route path="/cancel/:id" component={Cancellation} />
              <Route path="/thanks" component={ThankYou} />
              <Route path="/profile" component={Profile} />
              <Route path="/settle/thanks" component={SettleThankYou} />
              <Route path="/settle" component={SettleBalance} />
              <Route path="/" component={Booking} />
            </Switch>
          </div>
        </main>
        <footer className="text-left min-h-32 text-white">
          <div className="bg-brand-red">
            <div className="max-w-7xl mx-auto p-2 sm:p-4 lg:p-8 text-xl">
              <div>
                <h4 className="font-cursive uppercase">
                  Call Us:
                </h4>
                <h4 className="font-cursive">
                  07789103884
                </h4>
              </div>
              <div className="mt-6">
                <h4 className="font-cursive uppercase">
                  Email Us:
                </h4>
                <h4 className="font-cursive">
                  lovefoodjm@outlook.com
                </h4>
              </div>
            </div>
          </div>
          <div className="bg-brand-red bg-opacity-90">
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 py-4 text-sm">
              <div className="flex justify-between">
                <h6>
                  &copy; { format(new Date(), 'Y')} LOVE FOOD JM LTD.
                </h6>
                <h6>
                  LOVE FOOD JM LTD
                </h6>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Elements>
  )
}
