import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState, AppDispatch } from '.'
import { Baskets, BasketBooking } from "../types/studentBasket"
import { formatISO, getUnixTime, parseISO, startOfWeek } from 'date-fns';

interface basketState {
    baskets: Baskets
    loading: boolean
}

// Define the initial state using that type
const initialState: basketState = {
    baskets: {},
    loading: false,
}

export const cleanBasketBookings = createAsyncThunk<
    Baskets,
    {studentId?:string}|void,
    {
        dispatch: AppDispatch
        state: RootState
    }>(
        'bookings/cleanBasketBookings',
        async (meta, thunkApi) => {
            const { basket, menus, term, students } = thunkApi.getState();
            if (Object.keys(basket.baskets).length === 0) {
                return basket.baskets
            }
            let baskets = { ...basket.baskets };
            let retBaskets: Baskets = {};
            Object.entries(baskets).forEach(([studentId, currentBasket]) => {
                let currentStudent = students.students.find((student) => studentId === student.id)
                if (!currentStudent || !currentBasket) {
                    return;
                }
                if (meta?.studentId && meta.studentId !== studentId) {
                    retBaskets[studentId] = currentBasket
                    return;
                }
                Object.entries(currentBasket.bookings).forEach(([date, booking]) => {
                    const currentBooking = {...booking}
                    const bookingDate = parseISO(date)
                    const dateU = getUnixTime(bookingDate)
                    const menuDate = formatISO(startOfWeek(bookingDate, { weekStartsOn: 1 }), { representation: "date" });

                    let currentTerm = term.terms.find((t) => { return dateU >= t.start && dateU <= t.end });
                    let currentMenu = menus.menus.find((menu) => menu.id === currentTerm?.menus[menuDate])
                    if (!currentBooking.mealId
                        || currentBooking.mealId === currentBooking.oldMealId
                        || !currentTerm
                        || !currentMenu
                        || !currentMenu.meals?.find((meal) => meal.year_groups?.includes(currentStudent!.year))
                    ) {
                        return;
                    }
                    if (!retBaskets[studentId]) {
                        retBaskets[studentId] = {
                            studentId,
                            changes: 0,
                            bookings: {}
                        };
                    }
                    if (!retBaskets[studentId].bookings) {
                        retBaskets[studentId].bookings = {};
                    }
                    currentBooking.menuId = currentMenu.id
                    currentBooking.schoolId = currentTerm.schoolId
                    retBaskets[studentId].bookings[date] = currentBooking;
                    retBaskets[studentId].changes += 1;
                });
            });
            return retBaskets;
        },
        {
            condition: (meta, thunkAPI) => {
                const { basket } = thunkAPI.getState()
                if (basket.loading) {
                    return false
                }
            }
        }
    )


export const basketSlice = createSlice({
    name: 'basket',
    initialState,
    reducers: {
        addBasketBooking: (state, action: PayloadAction<BasketBooking>) => {
            if (!state.baskets[action.payload.studentId]) {
                state.baskets[action.payload.studentId] = {
                    studentId: action.payload.studentId,
                    bookings: {},
                    changes:0
                }
            }
            state.baskets[action.payload.studentId].bookings[action.payload.bookingDate] =
                { ...action.payload }
            if (action.payload.id) {
                state.baskets[action.payload.studentId].bookings[action.payload.bookingDate].oldMealId = action.payload.mealId
            }

        },
        updateBasketBooking: (state, action: PayloadAction<BasketBooking>) => {
            let current: BasketBooking = { ...state.baskets[action.payload.studentId].bookings[action.payload.bookingDate] };
            if (current.oldMealId !== action.payload.mealId) {
                state.baskets[action.payload.studentId].changes += 1;
                action.payload.hasChanged = true;
            } else {
                action.payload.hasChanged = false;
                state.baskets[action.payload.studentId].changes -= 1;
            }
            state.baskets[action.payload.studentId].bookings[action.payload.bookingDate] = action.payload
            if (current.id && current.mealId && !current.oldMealId) {
                state.baskets[action.payload.studentId].bookings[action.payload.bookingDate].oldMealId = current.mealId;
            }

        },
        removeBasketBooking: (state, action: PayloadAction<BasketBooking>) => {
            if (state.baskets[action.payload.studentId] && state.baskets[action.payload.studentId].bookings[action.payload.bookingDate]) {
                delete state.baskets[action.payload.studentId].bookings[action.payload.bookingDate];
            }
        },
        removeBasket: (state, action: PayloadAction<string>) => {
            if (state.baskets[action.payload]) {
                delete state.baskets[action.payload];
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(cleanBasketBookings.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(cleanBasketBookings.rejected, (state, action) => {
        })
        builder.addCase(cleanBasketBookings.fulfilled, (state, action) => {
            state.loading = false
            state.baskets = action.payload
        })
    }
})

export const { addBasketBooking, updateBasketBooking, removeBasketBooking, removeBasket } = basketSlice.actions

export default basketSlice.reducer
