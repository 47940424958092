// import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { AxiosRequestConfig } from "axios";
// import { useSelector } from "react-redux";
// import api from "../api";
// import { RootState } from "../store";

export const ORDER_STATUS_NEW = 'new'
export const ORDER_STATUS_CONFIRMED = 'confirmed'

// export interface Payment {
//   paymentIntent: string
//   clientSecret?: string
// }

// export interface Order {
//   id: string,
//   status: string
//   total: number
//   tax: number
//   subtotal: number
//   created_at: string
//   updated_at: string
//   schoolId: string
//   loading?: boolean
//   payment?: Payment
//   ordered_at?: number
// }

// interface OrderState {
//   loading: boolean
//   orders: Order[]
//   currentOrder?: Order
//   failedToComplete?: boolean
//   // lastUpdated?: number
// }

// const initialState: OrderState = {
//   loading: false,
//   orders: [],
// }

// export const fetchUserOrders = createAsyncThunk<Order[], void, { state: RootState }>(
//   'orders/fetchByUser',
//   async (_, thunkAPI) => {
//     const response = await api.get(`/bookings`)
//     let orders: Order[] = []; 
//     return response.data.data as Order[]
//   }
// )

// export const fetchUserOrdersSince = createAsyncThunk<Order[], number | undefined, { state: RootState }>(
//   'orders/fetchByUserSince',
//   async (since, thunkAPI) => {
//     let config: AxiosRequestConfig | undefined
//     if (since) {
//       config = { params: { since: since - (Polling.interval * 2) } }
//     }

//     const response = await api.get(`/orders`, config)

//     return response.data as Order[]
//   }
// )

// export const updateOrder = createAsyncThunk<
//   Order,
//   Order,
//   { state: RootState }
// >(
//   'orders/updateOrder',
//   async (order, thunkAPI) => {
//     const response = await api.put(`/orders/${order.id}`, { status: order.status })
//     return response.data as Order
//   }
// )

// export const completeOrder = createAsyncThunk(
//   'order/completeOrder',
//   async (order: Order, thunkAPI) => {
//     const response = await api.post(`/complete/${order.id}`)
//     return response.data as Order
//   }
// )

// export const orderSlice = createSlice({
//   name: 'orders',
//   initialState,
//   reducers: {
//     setCurrentOrder: (state, action: PayloadAction<Order | undefined>) => {
//       state.currentOrder = action.payload
//       state.failedToComplete = false
//     },
//     setFailedToComplete: (state, action: PayloadAction<boolean | undefined>) => {
//       state.failedToComplete = action.payload
//     }
//   },
//   extraReducers: (builder) => {
//     builder.addCase(fetchUserOrders.pending, (state, action) => {
//       state.loading = true
//     })
//     builder.addCase(fetchUserOrders.fulfilled, (state, action) => {
//       state.loading = false
//       state.orders = action.payload
//     })
//     builder.addCase(fetchUserOrders.rejected, (state, action) => {
//       state.loading = false
//     })
//     builder.addCase(fetchUserOrdersSince.pending, (state, action) => {
//       state.loading = true
//     })
//     builder.addCase(fetchUserOrdersSince.fulfilled, (state, action: PayloadAction<Order[]>) => {
//       state.loading = false
//       action.payload.forEach(order => {
//         const index = state.orders.findIndex(o => o.id === order.id)
//         if (index !== -1) {
//           state.orders[index] = order
//         } else {
//           state.orders.push(order)
//         }
//       })
//     })
//     builder.addCase(fetchUserOrdersSince.rejected, (state, action) => {
//       state.loading = false
//     })
//     builder.addCase(updateOrder.pending, (state, action) => {
//       const index = state.orders.findIndex(order => order.id == action.meta.arg.id)
//       if (index == -1) return
//       state.orders[index].loading = true
//     })
//     builder.addCase(updateOrder.fulfilled, (state, action) => {
//       const index = state.orders.findIndex(order => order.id == action.payload.id)
//       if (index != -1) {
//         state.orders[index] = action.payload
//       } else {
//         state.orders.push(action.payload)
//       }
//     })
//     builder.addCase(updateOrder.rejected, (state, action) => {
//       const index = state.orders.findIndex(order => order.id == action.meta.arg.id)
//       if (index == -1) return
//       state.orders[index].loading = false
//     })
//     builder.addCase(completeOrder.fulfilled, (state, action) => {
//       state.failedToComplete = false
//       const index = state.orders.findIndex(order => order.id == action.payload.id)
//       if (index != -1) {
//         state.orders[index] = action.payload
//       } else {
//         state.orders.push(action.payload)
//       }

//       delete state.currentOrder
//     })
//     builder.addCase(completeOrder.rejected, (state, action) => {
//       state.failedToComplete = true
//     })
//   },
// })

// export const selectOrder = (state: RootState, id: string) => state.order.orders.find(order => order.id == id)
// export const orderStatus = (order: Order) => {
//   // basic, primary, success, info, warning, danger, control
//   switch (order.status) {
//     case 'acknowledged':
//       return 'warning'
//     case 'ready':
//       return 'primary'
//     case 'collected':
//       return 'success'
//     default:
//       return 'basic'
//   }
// }

// export const { setCurrentOrder } = orderSlice.actions

// export const filteredOrders = createSelector<RootState, number, Order[], string[], Order[]>(
//   state => state.order.orders,
//   (_, filterIndex) => {
//     switch (filterIndex) {
//       case 0:
//         return [ORDER_STATUS_CONFIRMED]
//       case 1:
//         return [ORDER_STATUS_CONFIRMED]
//       default:
//         return []
//     }
//   },
//   (orders, statuses) => statuses.length > 0 ? orders.filter(o => statuses.includes(o.status)) : orders
// )

// export default orderSlice.reducer
