import { createTheme } from "@material-ui/core";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const theme = createTheme({
  palette: {
    primary: {
      light: '#cbcb5b',
      main: '#BEBE32',
      dark: '#858523',
      contrastText: '#fff',
    },
    secondary: {
      light: '#90a563',
      main: '#758F3D',
      dark: '#51642a',
      contrastText: '#000',
    },
  },
});