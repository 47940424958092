import { useEffect, useState } from "react"

import { addBusinessDays, addMonths, addWeeks, differenceInBusinessDays, format, formatISO, isMonday, isPast, isThisWeek, isWeekend, startOfWeek } from "date-fns"
import { differenceInCalendarDays, endOfMonth, fromUnixTime, getUnixTime, isFriday, nextFriday } from "date-fns/esm"
import { DetailedHTMLProps, InputHTMLAttributes } from "react"
import { Term } from "../../types/term"
import Heading from "./Heading"
import DateBlock from "./DateBlock"
import { Student } from "../../types/student"
import { Booking } from "../../types/booking"
import { StudentBasket } from "../../types/studentBasket"
import TermDateSelect from "./TermDateSelect"

export type BookingDateSelectProps = {
  terms: Term[]
  bookings: Booking[]
  basket?: StudentBasket
  currentStudent: Student
  onDateClick?: CallableFunction

} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export default function BookingDateSelect({ title, currentStudent, onDateClick, terms, bookings, basket, ...props }: BookingDateSelectProps) {

  const [currentDisplay, setCurrentDisplay] = useState<number>(0)
  const [selectedStudent, setSelectedStudent] = useState<Student>();

  useEffect(() => {
    if (currentStudent.id !== selectedStudent?.id) {
      setCurrentDisplay(0);
    }
    setSelectedStudent(currentStudent);
  }, [currentStudent, selectedStudent])
  const filteredTerms = terms.filter((term) => {
    return term.schoolId === currentStudent.schoolId && term.end >= getUnixTime(new Date())
  }).sort((a, b) => {
    return (a.start < b.start) ? -1 : 1
  })
  if (filteredTerms.length === 0) {
    return (
      <div className="bookingDateSelector">
        <div className="titleSection mb-2">
          <Heading
            text="There are currently no terms available to book meals for."
            fontSize="normal"
          />
        </div>
      </div>
    )
  }
  const filteredBookings = bookings.filter((booking) => {
    return booking.studentId === currentStudent.id && getUnixTime(new Date(booking.bookingDate)) >= getUnixTime(new Date())
  }).sort((a, b) => {
    return (getUnixTime(new Date(a.bookingDate)) > getUnixTime(new Date(b.bookingDate))) ? -1 : 1;
  });
  let termBlocks: any[] = [];
  const start = fromUnixTime(filteredTerms[0].start);
  const calendarEnd = addMonths(endOfMonth(start), currentDisplay);
  let loadMore = (differenceInBusinessDays(calendarEnd, fromUnixTime(filteredTerms[filteredTerms.length - 1].end)) < 0);
  if (loadMore && currentDisplay===0 && differenceInBusinessDays(start, calendarEnd) <= 10) {
    setCurrentDisplay(1);
  }
  filteredTerms.forEach((term) => {
    termBlocks.push(
      <div className="BookingDateSelect">
        <TermDateSelect 
          {...{
            title, 
            currentStudent, 
            onDateClick,
            term,
            bookings: filteredBookings,
            basket
          }} />
      </div>
    )
  })
  return (
    <div className="bookingDateSelector">
      {termBlocks}
    </div>
  )
}
