import { configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import studentsSlice from './studentsSlice'
import mealSlice from './mealSlice'
// import orderSlice from './orderSlice'
import parentSlice from './parentSlice'
import schoolTermSlice from './schoolTermSlice'
import bookingSlice from './bookingSlice'
import basketSlice from './basketSlice'
import menuSlice from './menuSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    students: studentsSlice,
    parent: parentSlice,
    meals: mealSlice,
    menus: menuSlice,
    term: schoolTermSlice,
    bookings: bookingSlice,
    basket: basketSlice
    // order: orderSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type ThunkAPI = { dispatch: AppDispatch, state: RootState }